var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-alert",
            { attrs: { prominent: "", type: "info", outlined: "" } },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Instance Creation with Latest Shared Content")
                ]),
                _vm._v(
                  " The instance(s) you will create will contain the most recent contents that you have shared so far (if any). "
                )
              ])
            ]
          ),
          _c(
            "v-tabs",
            {
              attrs: { color: "secondary" },
              model: {
                value: _vm.instanceInvitationMode,
                callback: function($$v) {
                  _vm.instanceInvitationMode = $$v
                },
                expression: "instanceInvitationMode"
              }
            },
            [
              _c("v-tab", [_vm._v("Invite via Link")]),
              _c("v-tab", [_vm._v("Invite via Email")]),
              _c("v-tab-item", [
                _vm.invalidGenerator === false
                  ? _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _vm.instanceInvitationMode ===
                        _vm.instanceInvitationTabOptions.INVITE_VIA_LINK
                          ? _c("v-form", [
                              _c("h3", { staticClass: "primary--text mt-4" }, [
                                _vm._v(
                                  "Share the following link (e.g. via a Learning Management System)"
                                )
                              ]),
                              _c("div", { staticClass: "my-2" }, [
                                _vm._v(
                                  "Participants will be able to sign up using the below link and receive their own separate instance for work"
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center"
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.firstInvGen,
                                      readonly: true
                                    }
                                  }),
                                  _c("CopyToClipboard", {
                                    attrs: {
                                      textToCopy: _vm.firstInvGen,
                                      buttonClass: "caption font-weight-bold",
                                      buttonColor: "secondary",
                                      isTextButton: true,
                                      buttonName: "copy to clipboard",
                                      iconButton: true,
                                      isSmall: false
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-banner",
                          {
                            staticClass: "mt-5",
                            attrs: { "two-line": "" },
                            scopedSlots: _vm._u([
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.creatingGenerator,
                                          text: "",
                                          color: "secondary"
                                        },
                                        on: {
                                          click: _vm.createInvitationGenerator
                                        }
                                      },
                                      [_vm._v("create invitation generator")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c(
                              "v-avatar",
                              {
                                attrs: {
                                  slot: "icon",
                                  color: "white",
                                  size: "40"
                                },
                                slot: "icon"
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-large": "",
                                      icon: "info",
                                      color: "info"
                                    }
                                  },
                                  [_vm._v(" info ")]
                                )
                              ],
                              1
                            ),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(
                                "No valid invitaiton generator were found. Please create a new generator to invite users."
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ]),
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        model: {
                          value: _vm.validMultipleInstances,
                          callback: function($$v) {
                            _vm.validMultipleInstances = $$v
                          },
                          expression: "validMultipleInstances"
                        }
                      },
                      [
                        _c("h4", { staticClass: "primary--text" }, [
                          _vm._v("Email addresses")
                        ]),
                        _c("v-textarea", {
                          attrs: {
                            required: "",
                            rows: "3",
                            "auto-grow": "",
                            disabled: _vm.inviting
                          },
                          on: { input: _vm.updateEmailString },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function() {
                                return [_vm._v("Enter user emails")]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.emailsInput,
                            callback: function($$v) {
                              _vm.emailsInput = $$v
                            },
                            expression: "emailsInput"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.emailsInput.length
                      ? _c("div", { staticClass: "mt-3 primary--text" }, [
                          _c("h3", [
                            _vm._v(
                              " A separate instance will be created for each of the following users who will receive an invitation email to join: "
                            )
                          ]),
                          _c(
                            "div",
                            _vm._l(_vm.emails, function(email, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "mt-3" },
                                [
                                  email.status ===
                                    _vm.emailValidationOutcomes
                                      .LOW_RISK_EMAIL ||
                                  email.status ===
                                    _vm.emailValidationOutcomes
                                      .UNKNOWN_RISK_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "success"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("check_circle")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold"
                                                },
                                                [_vm._v("validated")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : email.status ===
                                      _vm.emailValidationOutcomes
                                        .HIGH_RISK_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "error"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("cancel")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold"
                                                },
                                                [_vm._v("validation failed")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : email.status ===
                                      _vm.emailValidationOutcomes
                                        .UNVALIDATED_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("warning")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold"
                                                },
                                                [_vm._v("validation error")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm.validatingEmails === true
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(" " + _vm._s(email.email)),
                                          _c("v-progress-circular", {
                                            staticClass: "ml-1",
                                            attrs: {
                                              size: 20,
                                              indeterminate: "",
                                              color: "secondary"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("li", [_vm._v(_vm._s(email.email))])
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("InvitationErrorsDialog", {
            attrs: {
              showDialog: _vm.showInvitationErrorsDialog,
              errorType: _vm.validationErrorType,
              invalidEmails: _vm.invalidEmailsList
            },
            on: {
              proceedWithTheInvitation: function($event) {
                return _vm.inviteUsers($event.value)
              },
              inviteWithoutFetchingDistributed: function($event) {
                return _vm.createInstances(null, $event.value)
              },
              fetchAndInviteAgain: function($event) {
                return _vm.inviteUsers($event.value)
              }
            }
          })
        ],
        1
      ),
      _vm.instanceInvitationMode !==
      _vm.instanceInvitationTabOptions.INVITE_VIA_LINK
        ? _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column align-center",
                staticStyle: { width: "100%" }
              },
              [
                _vm.$store.state.userInfo.is_sysadmin === true
                  ? _c("v-checkbox", {
                      staticClass: "mb-1 mt-5 pa-0",
                      attrs: {
                        label: "Generate invitations but don't send emails"
                      },
                      model: {
                        value: _vm.generateInvitationLinksOnly,
                        callback: function($$v) {
                          _vm.generateInvitationLinksOnly = $$v
                        },
                        expression: "generateInvitationLinksOnly"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      loading: _vm.inviting || _vm.validatingEmails,
                      disabled:
                        _vm.validMultipleInstances === false ||
                        _vm.validatingEmails ||
                        _vm.inviting ||
                        !_vm.emailsInput,
                      color: "secondary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.validateEmailsAndInvite(true)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v("invite ")
                  ],
                  1
                ),
                _vm.error
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-4",
                        staticStyle: { width: "100%" },
                        attrs: { color: "error", icon: "warning", text: "" }
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Invitation Failure Error")
                          ]),
                          _vm._v(
                            " An error has occurred while inviting the users. Please try again and if the problem persists contact support@alphacruncher.com "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }